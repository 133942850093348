import { useEffect, useMemo, useState } from "react";
import {
  useAccount,
  useConnect,
  useDisconnect,
  useBalance,
  useWalletClient,
} from "wagmi";
import MezSdk from "mez-sdk/lib";
import "../styles/Profile.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ethers } from "ethers";
import { Account, Chain, Client, Transport } from "viem";
import ReferralPopup from "./popup";
import ReferralTable from "./Table";

export function clientToSigner(client: Client<Transport, Chain, Account>) {
  const { account, chain, transport } = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new ethers.BrowserProvider(transport, network);
  const signer = provider.getSigner(account.address);
  return signer;
}
export function Profile() {
  const { data: signer } = useWalletClient();
  const { address, isConnected } = useAccount();
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();
  const { disconnect } = useDisconnect();
  const { data } = useBalance({
    address,
  });

  const [rateAmount, setRateAmount] = useState("");
  const [buyTokenAmount, setBuyTokenAmount] = useState("");
  const [burnTokenAmount, setBurnTokenAmount] = useState("");
  const [beneficiaryAddress, setBeneficiaryAddress] = useState("");
  const [rewardTokenAmount, setRewardTokenAmount] = useState("");
  const [referalEmail, setReferalEmail] = useState("");
  const [referralLink, setReferralLink] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showTablePopup, setShowTablePopup] = useState(false);
  const [referalRecords, setReferalRecords] = useState<any[]>([]);

  console.log(signer);
  const sdk = new MezSdk(signer, {
    isMainnet: true,
    rpc: "https://bsc-dataseed.bnbchain.org/",
  });

  const handleSetRateAmountChange = (event: any) => {
    setRateAmount(event.target.value);
  };

  const handleBuyTokenAmountChange = (event: any) => {
    setBuyTokenAmount(event.target.value);
  };

  const handleBurnTokenAmountChange = (event: any) => {
    setBurnTokenAmount(event.target.value);
  };

  const handleRewardTokenAmountChange = (event: any) => {
    setRewardTokenAmount(event.target.value);
  };

  const handleBeneficiaryChange = (event: any) => {
    setBeneficiaryAddress(event.target.value);
  };

  const handleReferalEmailChange = (event: any) => {
    setReferalEmail(event.target.value);
  };

  const setMerkleRoot = async () => {
    await sdk.contract.setMerkleRootOnchain();
  };

  const setRateOnchain = async () => {
    if (!parseFloat(rateAmount)) {
      toast.error("Invaild rate amount");
      return;
    }

    await sdk.contract.setRateOnChain(rateAmount);
  };

  const startSeedRound = async () => {
    await sdk.contract.startSeedRound();
  };

  const endSeedRound = async () => {
    await sdk.contract.startSeedRound();
  };

  const startPresaleRound = async () => {
    await sdk.contract.startPresaleRound();
  };

  const endPresaleRound = async () => {
    await sdk.contract.endPresaleRound();
  };

  const buyToken = async () => {
    if (!parseFloat(buyTokenAmount)) {
      toast.error("Invaild buy amount");
      return;
    }

    await sdk.contract.buyTokens(buyTokenAmount);
  };

  const burnToken = async () => {
    if (!parseFloat(burnTokenAmount)) {
      toast.error("Invaild burn amount");
      return;
    }

    await sdk.contract.burn(burnTokenAmount);
  };

  const generateReferal = async () => {
    if (!referalEmail) {
      toast.error("Enter a valid email address");
      return;
    }

    setShowPopup(true);
    const res = await sdk.event.generateUniqueCode(referalEmail);
    setReferralLink(res.referalLink);
  };

  const generateReferalTable = async () => {
    setShowTablePopup(true);
    const res = await sdk.event.getAllReferralRecords();
    setReferalRecords(res);
  };

  const closeTablePopup = () => {
    setShowTablePopup(false);
  };

  const closePopup = () => {
    setShowPopup(false);
    setReferralLink("");
  };

  const rewardBeneficiary = async () => {
    if (!beneficiaryAddress && !ethers.isAddress(beneficiaryAddress)) {
      toast.error("Invaild beneficiary address");
      return;
    }

    if (!parseFloat(rewardTokenAmount)) {
      toast.error("Invaild reward amount");
      return;
    }

    await sdk.contract.reward(beneficiaryAddress, rewardTokenAmount);
  };

  const truncateAddress = (address: any) => {
    return `${address.substring(0, 6)}...${address.substring(
      address.length - 4
    )}`;
  };

  const formatBalance = (amount: any, symbol: any) => {
    return `${amount} ${symbol}`;
  };

  return (
    <>
      <ToastContainer />
      <div className="header-banner">
        <img
          src="https://res.cloudinary.com/dewvs0nf9/image/upload/v1701264650/Twitter_DP_smrpyj.png"
          alt="Mez Logo"
          className="logo"
        />
        <h1>Mez Admin</h1>
      </div>
      <div className="profile-container">
        {isConnected ? (
          <div>
            <div className="user-info">
              <div className="user-address">
                <img
                  src="https://res.cloudinary.com/dewvs0nf9/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1700056882/WhatsApp_Image_2023-11-12_at_1.30.55_PM_u1xna4.jpg"
                  alt="profile"
                  className="profile-image"
                />
                {truncateAddress(address)}
              </div>
              <div className="user-balance">
                Balance: {formatBalance(data?.formatted, data?.symbol)}
              </div>
              <button onClick={() => disconnect()}>Disconnect</button>
            </div>
            <div className="divider"></div>
            <div className="button-row">
              <div className="package-details">
                <div>Version: {sdk.version}</div>
                <div>
                  Environment: {sdk.config.isMainnet ? "Mainnet" : "Testnet"}
                </div>
              </div>
            </div>
            <h2>Actions</h2>
            <div className="action-buttons-container">
              <div className="action-buttons-left">
                <div className="button-row">
                  <button onClick={() => setRateOnchain()}>Set Rate</button>
                  <input
                    type="text"
                    placeholder={`Amount in ${data?.symbol}`}
                    onChange={(event) => handleSetRateAmountChange(event)}
                  />
                </div>
                <div className="button-row">
                  <button onClick={() => buyToken()}>Buy Tokens</button>
                  <input
                    type="text"
                    placeholder={`Amount in ${data?.symbol}`}
                    onChange={(event) => handleBuyTokenAmountChange(event)}
                  />
                </div>
                <div className="button-row">
                  <button onClick={() => burnToken()}>Burn Tokens</button>
                  <input
                    type="text"
                    placeholder={`Amount in MEZ`}
                    onChange={(event) => handleBurnTokenAmountChange(event)}
                  />
                </div>
                <div className="button-row">
                  <button onClick={() => rewardBeneficiary()}>
                    Reward Beneficiary
                  </button>
                  <div className="input-container">
                    <input
                      type="text"
                      placeholder={`Beneficiary address`}
                      onChange={(event) => handleBeneficiaryChange(event)}
                    />
                    <input
                      type="text"
                      placeholder={`Amount in MEZ`}
                      onChange={(event) => handleRewardTokenAmountChange(event)}
                    />
                  </div>
                </div>

                <div className="input-container">
                  <div className="button-row">
                    <button onClick={() => startSeedRound()}>
                      Start Seed Round
                    </button>
                  </div>
                  <div className="button-row">
                    <button onClick={() => endSeedRound()}>
                      End Seed Round
                    </button>
                  </div>
                </div>

                <div className="input-container">
                  <div className="button-row">
                    <button onClick={() => startPresaleRound()}>
                      Start Presale Round
                    </button>
                  </div>
                  <div className="button-row">
                    <button onClick={() => endPresaleRound()}>
                      End Presale Round
                    </button>
                  </div>
                </div>
                <div className="button-row">
                  <button onClick={() => setMerkleRoot()}>
                    Set Merkle Root Onchain
                  </button>
                </div>
                <div className="button-row">
                  <button onClick={() => generateReferal()}>
                    Generate referal code
                  </button>
                  <input
                    type="email"
                    placeholder={`email`}
                    onChange={(event) => handleReferalEmailChange(event)}
                  />
                  <ReferralPopup
                    referralLink={referralLink}
                    onClose={closePopup}
                    showLink={showPopup}
                  />
                </div>
                <div className="button-row">
                  <button onClick={() => generateReferalTable()}>
                    Show Referal Table
                  </button>
                  <ReferralTable
                    openModal={showTablePopup}
                    onClose={closeTablePopup}
                    referalRecords={referalRecords}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="connect-buttons">
            {connectors.map((connector) => (
              <button
                key={connector.id}
                disabled={!connector.ready}
                onClick={() =>
                  connect({
                    connector,
                  })
                }
              >
                {`Connect with ${connector.name}`}
                {!connector.ready && " (unsupported)"}
                {isLoading &&
                  connector.id === pendingConnector?.id &&
                  " (connecting)"}
              </button>
            ))}
            {error && <div className="error-message">{error.message}</div>}
          </div>
        )}
      </div>
    </>
  );
}
