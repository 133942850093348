import React from "react";
import Modal from "react-modal";
import { PulseLoader } from "react-spinners";

Modal.setAppElement("#root");

interface ReferralPopupProps {
  referralLink: string;
  showLink: boolean;
  onClose: () => void;
}

const ReferralPopup: React.FC<ReferralPopupProps> = ({
  referralLink,
  showLink,
  onClose,
}) => {
  return (
    <Modal
      isOpen={showLink}
      onRequestClose={onClose}
      contentLabel="Referral Link"
    >
      <div>
        <h2>Your Referral Link</h2>
        {!!referralLink ? (
          <p>{referralLink}</p>
        ) : (
          <div
            style={{
              marginTop: "15px",
            }}
          >
            <PulseLoader
              color={"#8276d0"}
              loading={true}
              cssOverride={{
                display: "block",
                margin: "0 auto",
                borderColor: "red",
              }}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
        <button onClick={onClose}>Close</button>
      </div>
    </Modal>
  );
};

export default ReferralPopup;
