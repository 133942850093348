import { configureChains, createConfig } from 'wagmi';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { infuraProvider } from 'wagmi/providers/infura';
import { publicProvider } from 'wagmi/providers/public';
import { goerli, mainnet, bsc, bscTestnet } from '@wagmi/core/chains';
const { chains, publicClient, webSocketPublicClient } = configureChains(
	[goerli, bscTestnet, mainnet, bsc],
	[
		infuraProvider({
			apiKey: 'a19a91904afc43208887e08ce29a17f7',
		}),
		publicProvider(),
	]
);

const config = createConfig({
	autoConnect: true,
	connectors: [
		new MetaMaskConnector({ chains }),
		new WalletConnectConnector({
			chains,
			options: {
				projectId: '558700e49aa9b8cb20d3ce319a0addc5',
				showQrModal: true,
			},
		}),
	],
	publicClient,
	webSocketPublicClient,
});

export { config };
