import React from "react";
import Modal from "react-modal";
import { PulseLoader } from "react-spinners";

Modal.setAppElement("#root");

interface ReferralTable {
  referalRecords: any[];
  openModal: boolean;
  onClose: () => void;
}

const ReferralTable: React.FC<ReferralTable> = ({
  openModal,
  onClose,
  referalRecords,
}) => {
  const tableHeaderStyle: React.CSSProperties = {
    border: "1px solid #ddd",
    padding: "10px",
    background: "#f2f2f2",
    textAlign: "left",
  };

  const tableCellStyle: React.CSSProperties = {
    border: "1px solid #ddd",
    padding: "10px",
    textAlign: "left",
  };

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={onClose}
      contentLabel="Referal Table"
    >
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h2>Table</h2>
          <button onClick={onClose}>Close</button>
        </div>

        {referalRecords?.length > 1 ? (
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>S/N</th>
                <th style={tableHeaderStyle}>Email</th>
                <th style={tableHeaderStyle}>Referral Link</th>
                <th style={tableHeaderStyle}>Referral Code</th>
                <th style={tableHeaderStyle}>Referral Count</th>
              </tr>
            </thead>
            <tbody>
              {referalRecords?.map((record, i) => (
                <tr key={i}>
                  <td style={tableCellStyle}>{i + 1}</td>
                  <td style={tableCellStyle}>{record?.email}</td>
                  <td style={tableCellStyle}>{record?.referalLink}</td>
                  <td style={tableCellStyle}>{record?.referalCode}</td>
                  <td style={tableCellStyle}>{record?.referalCount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "150px",
            }}
          >
            <PulseLoader
              color={"#8276d0"}
              loading={true}
              cssOverride={{
                display: "block",
                margin: "0 auto",
                borderColor: "red",
              }}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ReferralTable;
