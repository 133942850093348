import { WagmiConfig } from 'wagmi';
import { config } from './Auth';
import { Profile } from './Profile';

function App() {
	return (
		<WagmiConfig config={config}>
			<Profile />
		</WagmiConfig>
	);
}

export { App };
